@import './design-system/custom.scss';

/*
.page-item .page-link {
    border-radius: 5px
}
*/

/*
.table {
    
    border: $table-border-width solid $table-border-color;
    border-radius: 5px;
}

.table-borderless {
    border-radius: 5px;


    
    thead {
        border: $table-border-width solid $table-border-color;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom-width: 2 * $table-border-width;
    }
  }
  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid $color-primary-blue-10;
    -webkit-text-fill-color: $color-primary-grey-10;
    -webkit-box-shadow: 0 0 0px 1000px $color-primary-grey-30 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.close {
    color: $color-primary-grey-10;
    text-shadow: none;
    opacity: 1;
}

.bg {
    /* The image used */
    background-image: url('./design-system/img/background_img.png');


    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: calc(100vh - 55px);

    overflow: hidden;

    margin: 0 !important;
}

.btnRadio {
    background: none !important;
}

.row.footer {
    flex: 0 1 40px;
}

.box {
    display: flex;
    flex-flow: column;
}

.nav-text {
    color: $color-primary-grey-20;
    padding: 0.5rem 0.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

body,
html {
    height: 100%;
}

html {
    font-size: 0.8rem;
}

@include media-breakpoint-up(lg) {
    html {
        font-size: 1rem;
    }
}

#root {
    height: 100%;
}

hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: $color-primary-grey-20;
    ;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: $card-bg;

    font-size: 0.85rem;
    color: $color-primary-grey-20;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subtitle {
    color: $color-primary-grey-20;
}

.section {
    margin-top: 3rem;
}


.box::before {
    height: 54px;
    content: "";
    display: block;
}

#sidebar-wrapper {
    padding-right: 0;
    margin-bottom: 16px;
}

.pageSelector {
    .navbar {
        padding: 0;

        button {
            margin-top: 8px;
        }

        .nav-item {
            padding: 4px 0;

            a {
                font-size: 0.9em;
                padding: 0;
                line-break: anywhere;
            }


        }
    }

}

.navDropDownWithoutArrow a::after {
    display: none !important;
}

.bigIcon {
    font-size: 18px;
}

.formGroupNoMargin {
    margin: 0;

    .form-group {
        margin: 0;
    }
}

.documentVisibility {
    color: $color-primary-grey-20;
    font-size: 14px;

    button {
        font-size: 14px;
        padding: 0 2px 0 2px;
    }
}

.home-unity {

    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;




    .row {
        width: 80%;

        .col {
            background-color: $color-primary-grey-60;
            margin: 0 20px;
            height: 500px;
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-items: center;
            align-items: center;
            justify-content: center;
            position: relative;

            a {
                color: white;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 0;
                overflow: hidden;
                opacity: 1;

                .background {
                    overflow: hidden;
                    width: 100%;
                    z-index: 0;

                    img {
                        opacity: 0.7;
                        object-fit: contain;

                    }
                }

                div {
                    z-index: 10;
                }

                &:hover {
                    text-decoration: none;
                }
            }

        }
    }
}


.permissionAction {
    text-decoration: underline;
    cursor: pointer;
    color: $color-primary-blue-10;
}

.debugText {
    font-size: 14px;
    color: $color-primary-grey-20;
}

.wideNavDropDown>div:first-of-type {
    width: 250px;
}

.unityTableHeader {
    padding: 10px 12px;
    border-radius: 8px;
    width: 100%;
    color: $color-primary-grey-20;
    font-weight: bold;
}

.unityTable {
    border: 1px solid $color-primary-grey-40;
    background-color: $color-primary-grey-60;
    margin-bottom: 8px;
    padding: 10px 12px;
    border-radius: 8px;
    width: 100%;
    align-items: center;

    .unityCol {
        border-right: 2px solid #3b3b3e;
    }

    .text-gray {
        color: $color-primary-grey-20;
    }
}

.inviteMemberModal {
    .propTitle {
        text-transform: uppercase;
        font-weight: bold;
    }

    .col {
        margin-right: 10px;
    }
}

.teamOverview {

    border: 1px solid $color-primary-grey-40;
    padding: 16px;


    .title {
        font-size: 18px;
        font-weight: bold;
    }

    .unit {
        font-size: 15px;
        font-weight: normal;
        color: $color-primary-grey-20;
    }

    .count {
        font-size: 30px;
        font-weight: bold;

        .limit {
            font-size: 20px;
            font-weight: normal;
            color: $color-primary-grey-20;
        }
    }
}

.library {
    margin: 0 auto;
    justify-content: center;
    display: flex;


    .row {
        width: 100%;

        .leftPanel {
            .header {
                border-radius: 8px 8px 0 0;
                background-color: $color-primary-grey-40;
                font-weight: bold;
                color: #fff;
                padding: 8px;
            }

            .docType {
                background-color: $color-primary-grey-60;

                .row {
                    margin: 10px 0;
                }
            }
        }

        .rightPanel {

            .header {
                border-radius: 8px 8px 0 0;
                background-color: $color-primary-grey-40;
                font-weight: bold;
                color: #fff;
                padding: 8px;
            }

            .docType {
                background-color: $color-primary-grey-60;

                .row {
                    margin: 10px 0;
                }
            }
        }

    }
}

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}