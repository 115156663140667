///////////////////////FONTS////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700&display=swap');
$font-family-base: 'Roboto';
//////////////////////END_FONTS//////////////////////


///////////////////////FONTS///////////////////////
$font-heading-1-family: Roboto-Bold;
$font-heading-2-family: Roboto-Bold;
$font-heading-6-family: Roboto-Medium;
$font-heading-3-family: Roboto-Medium;
$font-heading-4-family: Roboto-Medium;
$font-heading-5-family: Roboto-Medium;

$font-label-12px-family: Roboto-Medium;
$font-label-14px-family: Roboto-Medium;
$font-label-16px-family: Roboto-Medium;

$font-body-12px-family: Roboto-Regular;
$font-body-14px-family: Roboto-Regular;
$font-body-16px-family: Roboto;
$font-body-18px-family: Roboto-Regular;

$size-font-heading-1: 48px;
$size-font-heading-2: 36px;
$size-font-heading-3: 32px;
$size-font-heading-4: 24px;
$size-font-heading-5: 20px;
$size-font-heading-6: 18px;

$size-font-body-12px: 12rem;
$size-font-body-14px: 14rem;
$size-font-body-16px: 16rem;
$size-font-body-18px: 18rem;

$size-font-label-12px: 12rem;
$size-font-label-14px: 14rem;
$size-font-label-16px: 16rem;

$h1-font-size: $size-font-heading-1;
$h2-font-size: $size-font-heading-2;
$h3-font-size: $size-font-heading-3;
$h4-font-size: $size-font-heading-4;
$h5-font-size: $size-font-heading-5;
$h6-font-size: $size-font-heading-6;

$font-family-base: $font-body-16px-family;
///////////////////////END-FONTS///////////////////////





//////////////////////COLORS////////////////
/// GREY
$color-primary-grey-10: #f0f0f0;
$color-primary-grey-20: #939498;
$color-primary-grey-30: #535356;
$color-primary-grey-40: #3b3b3e;
$color-primary-grey-50: #353538;
$color-primary-grey-60: #303033;
$color-primary-grey-70: #272729;
$color-primary-grey-80: #131314;
/// END_GREY

/// WHITE
$color-white: #ffffff;
/// END_WHITE

/// BLUE
$color-primary-blue-10: #4276EA;
$blue: #4276EA;
/// END_BLUE

/// GREEN
$color-primary-green-10: #27a658;
/// END_GREEN
//////////////////////END_COLORS////////////////



$min-contrast-ratio: 3;
$body-bg: #222223;
//$body-bg: $color-primary-grey-40;
$body-color: $color-primary-grey-10;


///////////////////////PAGINATION////////////////////////
$pagination-bg: $color-primary-grey-40;
$pagination-border-width: 0px;
$pagination-border: $color-primary-grey-50;
$pagination-active-bg: $color-primary-blue-10;
$pagination-color: $color-primary-grey-10;
$pagination-hover-bg: $color-primary-grey-30;
$pagination-hover-color: $color-primary-grey-10;
$pagination-disabled-bg: $color-primary-grey-50;
$pagination-disabled-border: $color-primary-grey-50;
//////////////////////END_PAGINATION//////////////////////



///////////////////////TABLE///////////////////////
$table-accent-bg: $color-primary-grey-50;
$table-bg: $color-primary-grey-40;
$table-border-color: #454548;
$table-color: $color-primary-grey-10;
$table-th-font-weight: 400;
$table-hover-bg: $color-primary-grey-30;
$table-striped-order: even;
$table-caption-color: $color-primary-grey-30;






//////////////////////END_TABLE//////////////////////



///////////////////////ACCORDION///////////////////////
$accordion-bg: $color-primary-grey-40;
$accordion-color: $color-primary-grey-10;
$accordion-button-active-bg: $color-primary-grey-30;
$accordion-button-active-color: $color-primary-grey-10;


//////////////////////END_ACCORDION//////////////////////



///////////////////////ALERTS///////////////////////
$alert-bg-level: -10;
$alert-border-level: 5;
$alert-color-level: 6;
//////////////////////END_ALERTS//////////////////////



/// ///////////////////////BADGES///////////////////////
$badge-padding-y: .5em;
$badge-padding-x: 1em;
$badge-border-radius: 2rem;
$badge-font-weight: $font-label-14px-family;
//////////////////////END_BADGES//////////////////////



///////////////////////BREADCRUMB///////////////////////
$breadcrumb-active-color: $color-primary-grey-10;
$breadcrumb-divider-color: $color-primary-grey-20;
//////////////////////END_BREADCRUMB//////////////////////





///////////////////////CARD////////////////////////
$card-bg: $color-primary-grey-40;
$card-cap-bg: $color-primary-grey-40;
$card-border-color: #454548;
$card-border-radius: 5px;

$list-group-bg: $color-primary-grey-40;
$list-group-color: $color-primary-grey-10;
$list-group-border-color: $color-primary-grey-80;

$list-group-item-bg-scale: 80%;
$list-group-item-color-scale: -60%;
//////////////////////END_CARD//////////////////////



///////////////////////FORM////////////////////////
$input-bg: $color-primary-grey-60;
$input-color: $color-primary-grey-10;
$input-disabled-bg: $color-primary-grey-50;
$input-focus-bg: $color-primary-grey-50;
$input-border-color: $color-primary-grey-40;
$input-focus-border-color: $color-primary-blue-10;
$input-group-addon-bg: $color-primary-grey-40;

$dropdown-link-active-color: $color-primary-grey-10;
$dropdown-link-hover-color: $color-primary-grey-10;
$dropdown-link-hover-bg: $color-primary-grey-30;
$form-select-indicator-color: $color-primary-grey-10;

$dropdown-color: $color-primary-grey-10;
$dropdown-bg: $color-primary-grey-40;
$dropdown-link-color: $color-primary-grey-10;

$form-check-input-border-radius: .15em;

$form-switch-color: $color-primary-grey-10;
$form-switch-focus-color: $color-primary-grey-10;

$form-range-track-bg: $color-primary-grey-80;
$form-range-thumb-bg: $color-primary-grey-10;
$form-range-thumb-active-bg: $color-primary-blue-10;

$form-text-color: $color-primary-grey-10;
$form-label-color: $color-primary-grey-10;
$input-placeholder-color: $color-primary-grey-20;
$input-plaintext-color: $color-primary-grey-20;
$text-muted: $color-primary-grey-20;

$form-check-input-bg: $color-primary-grey-40;
$form-check-input-border: $color-primary-grey-20;
//////////////////////END_FORM//////////////////////




///////////////////////MODAL///////////////////////
$modal-content-bg: $color-primary-grey-40;
$modal-content-color: $color-primary-grey-10;
$modal-content-border-color: #454548;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;
$btn-close-color: $color-primary-grey-10;
$btn-close-opacity: 1;
///////////////////////END_MODAL///////////////////////




///////////////////////NAVBAR///////////////////////
$navbar-light-color: $color-primary-grey-20;
$navbar-light-hover-color: $color-primary-grey-10;
$navbar-light-active-color: $color-primary-grey-10;
$navbar-light-disabled-color: $color-primary-grey-30;
$navbar-light-toggler-border-color: $color-primary-grey-80;
$navbar-light-brand-color: $color-primary-grey-10;
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

$nav-link-color: $color-primary-grey-20;
$nav-link-hover-color: $color-primary-grey-10;
$nav-link-disabled-color: $color-primary-grey-30;

$nav-tabs-border-color: $color-primary-grey-30;
$nav-tabs-link-active-color: $color-primary-grey-10;
$nav-tabs-link-active-bg: $color-primary-grey-30;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-hover-border-color: $color-primary-grey-30;
$nav-tabs-border-width: 1px;

$nav-pills-link-active-bg: $color-primary-grey-30;
$nav-pills-link-active-color: $color-primary-grey-10;
//////////////////////END_NAVBAR//////////////////////



///////////////////////PROGRESS_BAR////////////////////////
$progress-bg: $color-primary-grey-80;
$progress-border-radius: 3rem;
$progress-bar-bg: $color-primary-blue-10;
//////////////////////END_PROGRESS_BAR//////////////////////



///////////////////////SECTION////////////////////////
.section {
    margin-block: 3rem;
}

//////////////////////END_SECTION//////////////////////


$theme-colors: (
    "primary": $color-primary-blue-10,
    "secondary": $color-primary-grey-30,
    "success": $color-primary-green-10,
    "info": #009099,
    "warning": #f06e23,
    "danger": #eb4848,
    "light": $color-primary-grey-10,
    "dark": $color-primary-grey-40,
);




///////////////////////BUTTON////////////////////////
$btn-link-color: $color-primary-blue-10;
$btn-border-radius: 0.3rem;
$input-box-shadow: none;
$input-btn-focus-color: transparent;
$input-focus-width: 0rem;
$box-shadow: none;
$btn-focus-width: 0rem;
$btn-focus-box-shadow: 0;
$btn-transition: color 0.05s ease-in-out, background-color 0.05s ease-in-out, border-color 0.05s ease-in-out, box-shadow 0.05s ease-in-out;
$btn-active-box-shadow: 0 0 0 0rem transparent;
///////////////////////BUTTON////////////////////////


@import '~bootstrap/scss/bootstrap.scss';

///////////////////////BUTTON////////////////////////
.btn {
    border: none;
    outline: none;
    box-shadow: none;
    color: $color-primary-grey-10;
}


.btn-primary:focus {
    background-color: $color-primary-blue-10;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    background-color: #24407E;

}

.btn-primary:hover {
    background-color: #5C8AEF;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #38383a;
}

.btn-secondary:focus {
    background-color: $color-primary-grey-30;
}

.btn-secondary:hover {
    background-color: #636367;
}

//////////////////////END_BUTTON//////////////////////





///////////////////////ACCORDION////////////////////////
button.btn.btn-link {
    color: #80a8ff;
    text-decoration: underline;
}

///////////////////////END_ACCORDION////////////////////////



///////////////////////ALERT////////////////////////////
.fade.alert.alert-danger.show {
    color: $color-primary-grey-10;
    background-color: #301923;
    background-image: none;
    border-color: #8c0e2e;
}

a.alert-link {
    color: #ff4d4f;
}

.alert-heading.h4 {
    color: #ff4d4f;
}
///////////////////////END_ALERT////////////////////////



///////////////////////MODAL////////////////////////////
.modal-header span {
    font-size: 2.5rem;
    font-weight: 100;
    line-height: unset;
    color: rgb(255, 255, 255);
    text-shadow: 0 1px 0 #fff;
    opacity: 100%;
}

.modal-header .close {
    margin: -1.5rem -1rem -1rem auto;
}

///////////////////////END_MODAL////////////////////////


///////////////////////NAV-LINK////////////////////////////
.nav-link {
    color: $color-primary-grey-20;
}

.nav-link:hover {
    color: $color-primary-grey-10;
}


///////////////////////END_NAV_LINK////////////////////////



///////////////////////TABLE////////////////////////////
.table thead th {
    border-bottom: 1px solid $color-primary-grey-10;
}

tbody {
    color: $color-primary-grey-20;
}

.table {
    border-radius: 0px 0px 5px 5px;
    
}

thead {
    border-color: transparent;
}

select.ml-2 {
    margin-left: 0.5rem !important;
    background: $color-primary-grey-40;
    border-radius: 5px;
    padding: 5px;
    color: $color-primary-grey-10;
    border: none;
    outline: none;
}
///////////////////////END_TABLE////////////////////////////